<template>
  <div class="FoundersGrotesk">
    <div class="d-flex justify-content-center">
      <!-- nav overlay -->
      <div id="curtainMenu">
        <div id="overlaySideNav" class="overlay" @click="closeNav()"></div>

        <b-collapse
          id="collapseSidenav"
          class="sidenav"
          is-nav
          toggle="collapse"
          v-model="visible"
        >
          <a href="javascript:void(0)" class="closebtn" @click="closeNav()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 60 60"
              id="x"
              width="50"
              height="50"
            >
              <line
                x1="20"
                y1="20"
                x2="40"
                y2="40"
                stroke="#010101"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              ></line>
              <line
                x1="20"
                y1="40"
                x2="40"
                y2="20"
                stroke="#010101"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              ></line>
            </svg>
          </a>
          <b-navbar-nav id="navbar-nav" class="pt-5" vertical>
            <b-nav-item to="/#story" @click="closeNav()">OUR STORY</b-nav-item>
            <a v-b-toggle href="#com-dropdown" @click.prevent
              >THE ROOMS
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
              >
                <path
                  d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                />
              </svg>
            </a>
            <b-collapse id="com-dropdown" class="com-dropdown">
              <b-dropdown-item
                to="/#rooms"
                @click="
                  closeNav();
                  closeDropdown();
                "
                >ROOMS + BOOK
              </b-dropdown-item>
              <b-dropdown-item
                to="/rooms"
                @click="
                  closeNav();
                  closeDropdown();
                "
                >BROWSE ROOMS
              </b-dropdown-item>
            </b-collapse>

            <a v-b-toggle href="#com-dropdown1" @click.prevent
              >THE ROOF
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
              >
                <path
                  d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                />
              </svg>
            </a>
            <b-collapse id="com-dropdown1" class="com-dropdown">
              <b-dropdown-item
                to="/#roof"
                @click="
                  closeNav();
                  closeDropdown();
                "
                >ROOF
              </b-dropdown-item>
              <b-dropdown-item
                to="/roof"
                @click="
                  closeNav();
                  closeDropdown();
                "
                >BOOK THE ROOF
              </b-dropdown-item>
            </b-collapse>

            <a v-b-toggle href="#com-dropdown2" @click.prevent
              >THE NEIGHBOURHOOD
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
              >
                <path
                  d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                />
              </svg>
            </a>
            <b-collapse id="com-dropdown2" class="com-dropdown">
              <b-dropdown-item
                to="/#neighbourhood"
                @click="
                  closeNav();
                  closeDropdown();
                "
                >NEIGHBOURHOOD
              </b-dropdown-item>
              <b-dropdown-item
                to="/neighbourhood"
                @click="
                  closeNav();
                  closeDropdown();
                "
                >VIEW THE GUIDE
              </b-dropdown-item>
            </b-collapse>
            <b-nav-item to="/#art" @click="closeNav()">THE ARTISTS</b-nav-item>
            <b-nav-item to="/#faq" @click="closeNav()">FAQ</b-nav-item>
            <!-- <b-nav-item to="/#social" @click="closeNav()">SOCIAL</b-nav-item> -->
            <b-nav-item to="/press" @click="closeNav()">PRESS</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>

      <nav
        id="navbarLanding"
        ref="navbarLanding"
        class="navbar-test navbar-light"
        :class="{ indexNav: indexNav, bgTransparent: bgTransparent }"
      >
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          @click="openNav()"
          data-target="#collapseSidenav"
          aria-controls="collapseSidenav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span id="navbar-toggler-icon" class="navbar-toggler-icon-test menu"
            >MENU</span
          >
        </button>

        <div class="logo-container-test">
          <a href="/">
            <img
              src="/img/home/Ode_Logo.png"
              alt="Ode Toronto Logo"
              width="60px"
              class="cntr-img"
            />
          </a>
        </div>

        <div class="register-container-test">
          <router-link
            to="/book"
            class="FoundersGrotesk text-black register-link"
            role="button"
          >
            BOOK
          </router-link>
        </div>
      </nav>
      <div id="banner" class="banner-test">
        You'll always get the best price and full availability by booking
        direct.
        <router-link to="/book" class="book-btn">Book now</router-link>
        <button @click="hideBanner()" class="remove-banner-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60 60"
            id="x"
            width="25"
            height="25"
          >
            <line
              x1="20"
              y1="20"
              x2="40"
              y2="40"
              stroke="#FEFF66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            ></line>
            <line
              x1="20"
              y1="40"
              x2="40"
              y2="20"
              stroke="#FEFF66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            ></line>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  props: ["indexNav"],
  data() {
    return {
      visible: true,
      showMenu: false,
      bgTransparent: false,
    };
  },

  mounted() {
    if (this.indexNav) {
      this.bgTransparent = false;
    } else {
      this.bgTransparent = true;
    }
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  methods: {
    handleScroll() {
      if (this.indexNav) {
        this.bgTransparent = false;
      } else {
        this.bgTransparent = false;
      }
    },
    openNav() {
      // document.getElementById("collapseSidenav").style.width = "100%";
      // document.querySelector("body").style.overflow = "hidden";
      // document.getElementById("overlaySideNav").style.width = "100%";
      // document.getElementById("navbar-nav").style.opacity = "1";
      gsap.to("#collapseSidenav", {
        width: "100%",
        duration: 0.3,
        ease: "power4.inOut",
      });
      // gsap.to("#overlaySideNav", {
      //   width: "100%",
      //   delay: 0.2,
      //   duration: 0.3,
      //   ease: "power4.inOut",
      // });
      gsap.to("#navbar-nav", {
        opacity: 1,
        duration: 0.3,
        delay: 0.2,
        ease: "power4.inOut",
      });
      document.querySelector("body").style.overflow = "hidden";
    },
    closeNav() {
      // document.getElementById("collapseSidenav").style.width = "0%";
      // document.getElementById("overlaySideNav").style.width = "0%";
      // document.getElementById("navbar-nav").style.opacity = "0";
      // document.querySelector("body").style.overflow = "visible";
      gsap.to("#collapseSidenav", {
        width: 0,
        duration: 0.3,
        ease: "power4.inOut",
      });
      // gsap.to("#overlaySideNav", {
      //   width: 0,
      //   duration: 0.3,
      //   ease: "power4.inOut",
      // });
      gsap.to("#navbar-nav", {
        opacity: 0,
        duration: 0.3,
        ease: "power4.inOut",
      });
      document.querySelector("body").style.overflow = "visible";
    },
    closeDropdown() {
      // Add the logic to close the dropdown
      document.querySelectorAll(".com-dropdown").forEach((dropdown) => {
        dropdown.classList.remove("show");
      });
    },
    hideBanner() {
      document.getElementById("banner").style.display = "none";
      document.querySelector(".navbar-test").style.top = "10px";
    },
  },
  beforeDestroy() {
    this.closeNav();
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
body {
  transition: background-color 0.5s;
  transition: all 0.5s;
  overflow: visible;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 0.75;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
}
.logo-container {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 500px) {
    top: 120%;
  }
}
.logo-container-test {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.register-container {
  position: absolute;
  right: -32.5px;
  top: 78px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 9999999;
  @media screen and (max-width: 650px) {
    top: 96px;
    right: -38px;
  }
}

.register-container a {
  padding: 4px 20px;
  border: 2px solid black;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1.5px;
  white-space: nowrap;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  background-color: $m-yellow;
  text-decoration: none;
}

.remove-banner-btn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0%, -50%);
  @media screen and (max-width: 600px) {
    right: 1px;
  }
  @media screen and (max-width: 450px) {
    right: -5px;
  }
}

.register-container-test {
  z-index: 9999999;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0%, -50%);
}

.register-container-test a {
  // padding: 4px 20px;
  // border: 2px solid black;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  white-space: nowrap;
  // background-color: $m-yellow;
  text-decoration: none;
}

.banner {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 5vh;
  background-color: black;
  text-align: center;
  color: $m-yellow;
  font-size: 13.5px;
  padding: 6px 0px;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-yellow;
    text-decoration: underline;
    font-size: 13px;
    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 800px) {
    font-size: 12.5px;
    padding: 10px 0px;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    font-size: 12.5x;
    padding: 5px 35px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12.5px;
    padding: 5px 35px;
  }
}
.banner-test {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 5vh;
  background-color: black;
  text-align: center;
  color: $m-yellow;
  font-size: 14px;
  padding: 6px 0px;
  z-index: 2;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-yellow;
    text-decoration: none;
    font-size: 14px;
    @media screen and (max-width: 800px) {
      font-size: 13px;
    }
  }
  a:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 800px) {
    font-size: 13px;
    padding: 10px 0px;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    font-size: 12.5x;
    padding: 10px 28px 12px 28px;
    line-height: 15px;
  }
}
button {
  border: none;
  background-color: transparent;
  color: $m-yellow;
}

#navbarLanding {
  transition: background-color 0.5s;
}
.navbar-nav {
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
  opacity: 1;
}
.navbar {
  z-index: 1;
  width: 100%;
  position: fixed !important;
  transition: background-color 0.5s;
  align-content: center;
  height: 90px;
  top: 0;
}

.navbar-test {
  top: 50px;
  z-index: 8;
  width: 96%;
  height: 60px;
  position: fixed !important;
  transition: background-color 0.5s;
  align-content: center;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: rgba(0, 0, 0, 0.4);
  color: #eaeef4;
  border-radius: 5.6rem;
  @media screen and (max-width: 800px) {
    top: 50px;
  }
  @media screen and (max-width: 450px) {
    top: 60px;
  }
}
.navbar-light .navbar-toggler {
  color: transparent;
  border-color: unset;
}
.navbar-toggler-icon {
  background-image: none;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1.5px;
  position: absolute;
  left: 5px;
  top: 109%;
  @media screen and (max-width: 500px) {
    top: 120%;
  }
}
.navbar-toggler-icon-test {
  background-image: none;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  position: absolute;
  margin: 0;
  left: 20px;
  top: 50%;
  transform: translate(0%, -50%);
}
.menu {
  color: black;
}
#curtainMenu {
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: width;
  z-index: 9;
}
.sidenav {
  .closebtn {
    color: black;
    text-shadow: none;
    opacity: 1;
    font-size: 60px;
    height: 60px;
    width: auto;
    position: absolute;
    top: -10px;
    right: 15px;
    padding-right: 2%;
  }
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: white;
  display: block;
  overflow-x: hidden;
  padding-top: 5%;
  transition-timing-function: ease-in-out;
  transition-property: width;
  transition-duration: 0.2s;
  opacity: 1;
  // transition: 0.5s;
  //   display: grid; /* to make it vertical align */
}
.sidenav a {
  font-weight: 500;
  text-decoration: none;
  font-size: 30px;
  color: black;
  // display: block;
  transition: slow 0.5s;
  text-align: left;
  padding: 0px;
  padding-left: 60px;
  @media screen and (max-width: 700px) {
    font-size: 1.6rem;
  }
}
.com-dropdown {
  font-size: 25px;
  padding-left: 60px;
  @media screen and (max-width: 700px) {
    font-size: 1.2rem;
  }
}

.indexNav {
  position: fixed;
  background-color: transparent;
}
.bgTransparent {
  background-color: transparent !important;
}
</style>

<style>
.dropdown-item {
  padding: 0px;
  padding-left: 1rem;
}
.side-nav {
  font-size: 10px;
}
</style>
